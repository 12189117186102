import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from "../components/Navbar/Navbar"
import UslugiPages from "../components/Uslugi/UslugiPages"
import {BoxTextPages, BigTitlePages, Flex, FlexContent, TitleServices, ListServices} from '../components/Uslugi/UslugiStyled'
import { StaticImage }from "gatsby-plugin-image"

import { theme } from "../components/theme.js";
import { ThemeProvider } from "styled-components"


import SEO from "../components/seo"

const labolatorium = () => {
  return (

    <>
    <ThemeProvider theme={theme}>
    <SEO 
    title="Laboratorium"
    description="Oferujemy szeroki wachlarz usług weterynaryjnych obejmujący leczenie psów, kotów, zwierząt futerkowych i gryzoni. Badania laboratoryjne wykonujemy w naszej Lecznicy.
     Jeżeli szukasz specjalisty lekarza weterynarii w Kaliszu - zapraszamy do Lecznicy dla Zwierząt znajdującej się w Kaliszu przy ul. Lipowej 17-21." 
     keywords="laboratorium weterynaryjne kalisz"
    />
    <Navbar />

    <BigTitlePages style={{justifyContent: 'center'}}
         whileInView={{y: 0, opacity:1}}
         initial={{ y:50, opacity:0}}
         transition={{delay:0.25, duration: 0.75}}
         >Laboratorium</BigTitlePages>  
         
         <FlexContent>
          <UslugiPages />

 <Flex> 
  
 <StaticImage
    src="../images/laboratorium.jpg"
    alt="Laboratorium zwierzęta weterynarz Kalisz"
    width={700}
    placeholder="blurred"
    className="staticimagepages"
    />
 
    <BoxTextPages>

<TitleServices>Badania laboratoryjne</TitleServices>
<ListServices>Analiza krwi (morfologia, biochemia)

</ListServices>
<ListServices> Testy w kierunku chorób zakaźnych ( np: białaczki i niedoboru immunologicznego kotów, parwowirozy i nosówki psów)</ListServices>
<ListServices> Badania moczu</ListServices>
<ListServices> Wszystkie badania wykonywane są  w naszej Lecznicy, przy użyciu sprzętu firmy Abaxis, który umożliwia pełną diagnostykę podczas wizyty
</ListServices>



    </BoxTextPages>
    
</Flex>
</FlexContent>
<Footer />
</ThemeProvider>
    </>
  )
}

export default labolatorium